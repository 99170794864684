import React, { useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from 'react-ga4'; // Ensure you use the right package for GA4
// import Navbar from "./Components/Navbar/Navbar";
import Maintenance from './Components/Maintenance/Maintenance';
// import Nav from './Components/Navbar/Nav';
// import Contact from "./Components/Contact/Contact";
// import ReadKtn from "./Components/Ktn/ReadKtn";
// import Home from "./Components/Home/Home";
// import CusttomerCentricMarketing from "./Components/Ktn/ThoughtsToChop/CusttomerCentricMarketing";
// import PracticalStrategies from "./Components/Ktn/ThoughtsToChop/PracticalStrategies";
// import OvercomingImposterSyndrome from "./Components/Ktn/ThoughtsToChop/OvercomingImposterSyndrome";
// import TheTruth from "./Components/Ktn/ThoughtsToChop/TheTruth";
// import TellYourStory from "./Components/Ktn/ThoughtsToChop/TellYourStory";
// import Talkatives from "./Components/Ktn/ThoughtsToChop/Talkatives";
// import RobtheWorld from "./Components/Ktn/ThoughtsToChop/RobtheWorld";
// import ParentsChildren from "./Components/Ktn/ThoughtsToChop/ParentsChildren";
// import MeditationInsomnia from "./Components/Ktn/ThoughtsToChop/MeditationInsomnia";
// import Gym from "./Components/Ktn/ThoughtsToChop/Gym";
// import OriginalLife from "./Components/Ktn/ThoughtsToChop/OriginalLife";
// import GrowYourConfidence from "./Components/Ktn/ThoughtsToChop/GrowYourConfidence";
// import MasteringColdPitching from "./Components/Ktn/MarketingAndSales/MasteringColdPitching";
// import BuildingStableIncome from "./Components/Ktn/MarketingAndSales/BuildingStableIncome";
// import PersuasiveStrategies from "./Components/Ktn/MarketingAndSales/PersuasiveStrategies";
// import CraftingColdEmails from "./Components/Ktn/MarketingAndSales/CraftingColdEmails";
// import SucceedBusiness from "./Components/Ktn/MarketingAndSales/SucceedBusiness";
// import PracticalStrategiesJourney from "./Components/Ktn/MarketingAndSales/PracticalStrategies";
// import LumpSumProjects from "./Components/Ktn/MarketingAndSales/LumpSumProjects";
// import MarketingLaw from "./Components/Ktn/MarketingAndSales/MarketingLaw";
// import FeelingOfSelling from "./Components/Ktn/MarketingAndSales/FeelingOfSelling";
// import RetainClients from "./Components/Ktn/MarketingAndSales/RetainClients";
// import Whore from "./Components/Ktn/MarketingAndSales/Whore";
// import ColdOutreachStrategy from "./Components/Ktn/MarketingAndSales/ColdOutreachStrategy";
// import IncreasePercievedValue from "./Components/Ktn/MarketingAndSales/IncreasePercievedValue";
// import DebunkingMyths from "./Components/Ktn/Afroprenuership/DebunkingMyths";
// import CraftCompellingContent from "./Components/Ktn/Afroprenuership/CraftCompellingContent";
// import SevenStrategies from "./Components/Ktn/Afroprenuership/SevenStrategies";
// import UnderstandingYourRights from "./Components/Ktn/Afroprenuership/UnderstandingYourRights";
// import ShapePublicContent from "./Components/Ktn/Afroprenuership/ShapePublicContent";
// import PerfectGhostwriterProject from "./Components/Ktn/Afroprenuership/PerfectGhostwriterProject";
// import ValueSells from "./Components/Ktn/Afroprenuership/ValueSells";
// import PowerfulPlayfulPersistence from "./Components/Ktn/Afroprenuership/PowerfulPlayfulPersistence";
// import GrowYourBusiness from "./Components/Ktn/Afroprenuership/GrowYourBusiness";
// import MasterPricing from "./Components/Ktn/Afroprenuership/MasterPricing";
// import MasteringArtGhostwriting from "./Components/Ktn/Afroprenuership/MasteringArtGhostwriting";
// import EmbracingUncertainty from "./Components/Ktn/Afroprenuership/EmbracingUncertainty";
// import CashCow from "./Components/Ktn/Afroprenuership/CashCow";
// import TrialAndError from "./Components/Ktn/Afroprenuership/TrialAndError";
// import ColdOutreachAfro from "./Components/Ktn/Afroprenuership/ColdOutreachAfro";
// import OvercomeImposterSyndromeAfro from "./Components/Ktn/Afroprenuership/OvercomeImposterSyndromeAfro";
// import TalentLeverage from "./Components/Ktn/Afroprenuership/TalentLeverage";
// import BillClientsAfro from "./Components/Ktn/Afroprenuership/BillClientsAfro";
// import DontNiche from "./Components/Ktn/Afroprenuership/DontNiche";
// import Footer from "./Components/Footer/Footer";
// import FAQ from "./Components/Faq/Faq";
// import VideoContentEditing from "./Components/Services/Marketing/VideoContentEditing";
// import BrandStrategy from "./Components/Services/Marketing/BrandStrategy";
// import Marketing from "./Components/Services/Marketing/Marketing";
// import Copywriting from "./Components/Services/Copywriting/Copywriting";
// import EmailOutreach from "./Components/Services/Copywriting/EmailOutreach";
// import WebsiteContent from "./Components/Services/Copywriting/WebsiteContent";
// import SocialMediaContent from "./Components/Services/Copywriting/SocialMediaContent";
// import ThoughtLeadershipPieces from "./Components/Services/Ghostwriting/ThoughtLeadershipPieces";
// import MemiorBiography from "./Components/Services/Ghostwriting/MemiorBiography";
// import GeneralNonFriction from "./Components/Services/Ghostwriting/GeneralNonFriction";
// import BusinessBooks from "./Components/Services/Ghostwriting/BusinessBooks";
// import BlogContent from "./Components/Services/Ghostwriting/BlogContent";
// import Speeches from "./Components/Services/Ghostwriting/Speeches";
// import SelfHelp from "./Components/Services/Ghostwriting/SelfHelp";
// import Fiction from "./Components/Services/Ghostwriting/Fiction";
// import Ghostwriting from "./Components/Services/Ghostwriting/Ghostwriting";
// import EffectivelyGhostwriter from "./Components/Ktn/Afroprenuership/EffectivelyGhostwriter";
// import HighValueClients from "./Components/Ktn/Afroprenuership/HighValueClients";
// import GhostwriteSeoContent from "./Components/Ktn/Afroprenuership/GhostwriteSeoContent";
// import OvercomeWritersBlock from "./Components/Ktn/Afroprenuership/OvercomeWritersBlock";
// import EstablishYourself from "./Components/Ktn/Afroprenuership/EstablishYourself";
// import Childrens from "./Components/Services/Ghostwriting/Childrens";
// import HighPerformanceTeam from "./Components/Ktn/Afroprenuership/HighPerformanceTeam";
// import GhostwritingForAuthors from "./Components/Ktn/Afroprenuership/GhostwritingForAuthors";
// import PricingPage from "./Components/Pricing/PricingPage";
// import GhostwritingInterviews from "./Components/Ktn/Afroprenuership/GhostwritingInterviews";
// import PersonalBrand from "./Components/Ktn/Afroprenuership/PersonalBrand";
// import FiveSigns from "./Components/Ktn/Afroprenuership/FiveSigns";
// import TopSkill from "./Components/Ktn/Afroprenuership/TopSkill";
// import WritingABook from "./Components/Ktn/Afroprenuership/WritingABook";
// import KeyLessons from "./Components/Ktn/Afroprenuership/KeyLessons";
// import HelpClientChoose from "./Components/Ktn/Afroprenuership/HelpClientChoose";
// import TellYourStoryAfro from "./Components/Ktn/Afroprenuership/TellYourStoryAfro";
// import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
// import EffectiveTips from "./Components/Ktn/Afroprenuership/EffectiveTips";
// import ReadingGhostwriters from "./Components/Ktn/Afroprenuership/ReadingGhostwriters";
// import CreateLongTermClient from "./Components/Ktn/Afroprenuership/CreateLongTermClient";
// import TurningClient from './Components/Ktn/Afroprenuership/TurningClient';
// import OvercomeAnxiety from './Components/Ktn/Afroprenuership/OvercomeAnxiety';
// import EarnMoreMoney from './Components/Ktn/Afroprenuership/EarnMoreMoney';
// import GhostwritingOffer from './Components/Ktn/Afroprenuership/GhostwritingOffer';
// import HelpGhostwriters from './Components/Ktn/Afroprenuership/HelpGhostwriters';
// import TopExecutive from './Components/Ktn/Afroprenuership/TopExecutive';
// import BestSelling from './Components/Ktn/Afroprenuership/BestSelling';
// import TenSigns from './Components/Ktn/Afroprenuership/TenSigns';
// import IdeaPublishing from './Components/Ktn/Afroprenuership/IdeaPublishing';
// import SuccessPartnered from './Components/Ktn/Afroprenuership/SuccessPartnered';
// import NewsletterModal from './Components/Newsletter/Newsletter';


const TRACKING_ID = "G-QHY6FFWDGP";
function App() {

  // const [isModalOpen, setIsModalOpen] = useState(false);


  // useEffect(() => {
  //   // Trigger this effect only once when the component is mounted
  //   console.log('Page loaded, showing the modal');
  //   setIsModalOpen(true);
  // }, []);  // The empty dependency array makes sure this effect runs only once after the initial render

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  useEffect(() => {
    // Initialize ReactGA with your tracking ID
    ReactGA.initialize(TRACKING_ID);
    // Send a pageview event to Google Analytics on component mount
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const handlePageView = (location) => {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    };

    // Track page views on route changes
    handlePageView(window.location);

    // Listen to route changes
    window.addEventListener('popstate', () => handlePageView(window.location));
    return () => {
      window.removeEventListener('popstate', () => handlePageView(window.location));
    };
  }, []);
  

  return (
    <Router>
      {/* <Navbar /> */}
      {/* <Nav /> */}
      {/* <NewsletterModal isOpen={isModalOpen} onClose={closeModal} /> */}
      {/* <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/readktn" exact element={<ReadKtn />} />
        <Route path="/contact-us" exact element={<Contact />} />
        <Route
          path="/ktn/thought-to-chop/customer-centric-marketing"
          exact
          element={<CusttomerCentricMarketing />}
        />
        <Route
          path="/ktn/thought-to-chop/speak-with-confidence"
          exact
          element={<PracticalStrategies />}
        />
        <Route
          path="/ktn/thought-to-chop/overcoming-imposter-syndrome"
          exact
          element={<OvercomingImposterSyndrome />}
        />
        <Route
          path="/ktn/thought-to-chop/the-truth"
          exact
          element={<TheTruth />}
        />
        <Route
          path="/ktn/thought-to-chop/tell-your-story"
          exact
          element={<TellYourStory />}
        />
        <Route
          path="/ktn/thought-to-chop/talkatives"
          exact
          element={<Talkatives />}
        />
        <Route
          path="/ktn/thought-to-chop/rob-the-world"
          exact
          element={<RobtheWorld />}
        />
        <Route
          path="/ktn/thought-to-chop/parents-dont-hate-children"
          exact
          element={<ParentsChildren />}
        />
      
        <Route
          path="/ktn/thought-to-chop/meditation-or-insomnia"
          exact
          element={<MeditationInsomnia />}
        />
        <Route
          path="/ktn/thought-to-chop/gym-isnt-neccessary"
          exact
          element={<Gym />}
        />{" "}
        <Route
          path="/ktn/thought-to-chop/how-original-is-your-life"
          exact
          element={<OriginalLife />}
        />
        <Route
          path="/ktn/thought-to-chop/grow-your-confidence"
          exact
          element={<GrowYourConfidence />}
        />
        <Route
          path="/ktn/marketing-and-sales/practical-strategies-journey"
          exact
          element={<PracticalStrategiesJourney />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/mastering-cold-pitching"
          exact
          element={<MasteringColdPitching />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/building-stable-income"
          exact
          element={<BuildingStableIncome />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/persuasive-startegies"
          exact
          element={<PersuasiveStrategies />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/crafting-cold-emails"
          exact
          element={<CraftingColdEmails />}
        />
        <Route
          path="/ktn/marketing-and-sales/succeed-in-any-business"
          exact
          element={<SucceedBusiness />}
        />
        <Route
          path="/ktn/marketing-and-sales/lumpsum-project"
          exact
          element={<LumpSumProjects />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/marketing-law"
          exact
          element={<MarketingLaw />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/hate-the-feeling-of-selling"
          exact
          element={<FeelingOfSelling />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/land-and-retain-clients"
          exact
          element={<RetainClients />}
        />
        <Route
          path="/ktn/marketing-and-sales/whore"
          exact
          element={<Whore />}
        />{" "}
        <Route
          path="/ktn/marketing-and-sales/cold-outreach-strategy"
          exact
          element={<ColdOutreachStrategy />}
        />
        <Route
          path="/ktn/marketing-and-sales/increase-your-percieved-value"
          exact
          element={<IncreasePercievedValue />}
        />
        <Route
          path="/ktn/afroprenuership/effectively-work-ghostwriter"
          exact
          element={<EffectivelyGhostwriter />}
        />
        <Route
          path="/ktn/afroprenuership/ghostwrite-seo-content"
          exact
          element={<GhostwriteSeoContent />}
        />
        <Route
          path="/ktn/afroprenuership/overcome-writers-block"
          exact
          element={<OvercomeWritersBlock />}
        />
        <Route
          path="/ktn/afroprenuership/establish-yourself-as-a-thought-leader"
          exact
          element={<EstablishYourself />}
        />
          <Route
          path="/ktn/afroprenuership/building_high_performing_ghostwriting_team"
          exact
          element={<HighPerformanceTeam />}
        />
        <Route
          path="/ktn/afroprenuership/ghostwriting-for-authors"
          exact
          element={<GhostwritingForAuthors />}
        /> <Route
        path="/ktn/afroprenuership/ghostwriting-interview"
        exact
        element={<GhostwritingInterviews />}
      />


<Route
        path="/ktn/afroprenuership/build-your-personal-brand"
        exact
        element={<PersonalBrand />}
      />
      
        <Route
          path="/ktn/afroprenuership/attract-highvalue-clients"
          exact
          element={<HighValueClients />}
        />
        <Route
          path="/ktn/afroprenuership/debunking-myths"
          exact
          element={<DebunkingMyths />}
        />
        <Route
          path="/ktn/afroprenuership/craft-compelling-content"
          exact
          element={<CraftCompellingContent />}
        />
        <Route
          path="/ktn/afroprenuership/seven-top-strategies"
          exact
          element={<SevenStrategies />}
        />
        <Route
          path="/ktn/afroprenuership/understanding-your-rights"
          exact
          element={<UnderstandingYourRights />}
        />
        <Route
          path="/ktn/afroprenuership/shape-public-content"
          exact
          element={<ShapePublicContent />}
        />
        <Route
          path="/ktn/afroprenuership/perfect-ghostwriter-project"
          exact
          element={<PerfectGhostwriterProject />}
        />
        <Route
          path="/ktn/afroprenuership/build-sustainable-business"
          exact
          element={<ValueSells />}
        />
        <Route
          path="/ktn/afroprenuership/powerful-playful-persistence"
          exact
          element={<PowerfulPlayfulPersistence />}
        />
        <Route
          path="/ktn/afroprenuership/grow-your-business"
          exact
          element={<GrowYourBusiness />}
        />
        <Route
          path="/ktn/afroprenuership/master-premium-pricing"
          exact
          element={<MasterPricing />}
        />
        <Route
          path="/ktn/afroprenuership/mastering-the-art-of-ghostwriting"
          exact
          element={<MasteringArtGhostwriting />}
        />
        <Route
          path="/ktn/afroprenuership/embracing-uncertainty"
          exact
          element={<EmbracingUncertainty />}
        />
        <Route
          path="/ktn/afroprenuership/what-is-your-cash-cow"
          exact
          element={<CashCow />}
        />
        <Route
          path="/ktn/afroprenuership/trial-and-error"
          exact
          element={<TrialAndError />}
        />
        <Route
          path="/ktn/afroprenuership/cold-outreach"
          exact
          element={<ColdOutreachAfro />}
        />
        <Route
          path="/ktn/afroprenuership/overcome-imposter-syndrome"
          exact
          element={<OvercomeImposterSyndromeAfro />}
        />
        <Route
          path="/ktn/afroprenuership/talent-leverage"
          exact
          element={<TalentLeverage />}
        />
        <Route
          path="/ktn/afroprenuership/bill-clients"
          exact
          element={<BillClientsAfro />}
        />{" "}
        <Route
          path="/ktn/afroprenuership/dont-niche-away-your-profit"
          exact
          element={<DontNiche />}
        />{" "}

<Route
          path="/ktn/afroprenuership/five-signs-ghostwriters-need"
          exact
          element={<FiveSigns />}
        />{" "}

<Route
          path="/ktn/afroprenuership/top-skill-every-ghostwriter-should-have"
          exact
          element={<TopSkill />}
        />{" "}

<Route
          path="/ktn/afroprenuership/writing-a-book-is-great-for-your-business"
          exact
          element={<WritingABook />}
        />{" "}

<Route
          path="/ktn/afroprenuership/key-lessons-from-famous-ghostwritten-books"
          exact
          element={<KeyLessons />}
        />{" "}

<Route
          path="/ktn/afroprenuership/how-to-help-clients-choose-the-best-publishing-path"
          exact
          element={<HelpClientChoose />}
        />{" "}


        
<Route
          path="/ktn/afroprenuership/tell-your-story-the-power-of-ghostwriting"
          exact
          element={<TellYourStoryAfro />}
        />{" "}

<Route
          path="/ktn/afroprenuership/expert-tips-to-publicize-your-book"
          exact
          element={<EffectiveTips />}
        />{" "}

        
<Route
          path="/ktn/afroprenuership/why-reading-is-important-for-ghostwriters"
          exact
          element={<ReadingGhostwriters />}
        />{" "}


        
<Route
          path="/ktn/afroprenuership/how-to-create-long-term-client-relationships-as-a-ghostwriter"
          exact
          element={<CreateLongTermClient />}
        />{" "}


<Route
          path="/ktn/afroprenuership/turning-client-feedback-into-success"
          exact
          element={<TurningClient />}
        />{" "}



<Route
          path="/ktn/afroprenuership/how-ghostwriters-can-overcome-anxiety-when-sending-their-first-pitch"
          exact
          element={<OvercomeAnxiety />}
        />{" "}

        
<Route
          path="/ktn/afroprenuership/seven-proven-strategies-to-earn-more-money-as-a-ghostwriter"
          exact
          element={<EarnMoreMoney />}
        />{" "}

<Route
          path="/ktn/afroprenuership/how-to-create-a-tempting-ghostwriting-offer"
          exact
          element={<GhostwritingOffer />}
        />{" "}

<Route
          path="/ktn/afroprenuership/how-a-ghostwriter-can-help-you-establish-thought-leadership-through-a-book"
          exact
          element={<HelpGhostwriters />}
        />{" "}

<Route
          path="/ktn/afroprenuership/why-top-executives-choose-ghostwriters"
          exact
          element={<TopExecutive />}
        />{" "}

<Route
          path="/ktn/afroprenuership/the-secret-to-a-bestselling-book"
          exact
          element={<BestSelling />}
        />{" "}

<Route
          path="/ktn/afroprenuership/ten-signs-youre-prepared-to-hire-a-ghostwriter"
          exact
          element={<TenSigns />}
        />{" "}

<Route
          path="/ktn/afroprenuership/from-idea-to-publishing-what-to-expect-when-you-work-with a-ghostwriter"
          exact
          element={<IdeaPublishing />}
        />{" "}

<Route
          path="/ktn/afroprenuership/three-success-stories-of-entrepreneurs-who-partnered-with-ghostwriters"
          exact
          element={<SuccessPartnered />}
        />{" "}



        <Route path="/Faq" exact element={<FAQ />} />
        <Route
          path="/video-production"
          exact
          element={<VideoContentEditing />}
        />{" "}
        <Route path="/brand-strategy" exact element={<BrandStrategy />} />{" "}
        <Route path="/marketing" exact element={<Marketing />} />
        <Route path="/copywriting" exact element={<Copywriting />} />
        <Route path="/email-outreach" exact element={<EmailOutreach />} />
        <Route path="/website-content" exact element={<WebsiteContent />} />
        <Route path="/social-media" exact element={<SocialMediaContent />} />
        <Route path="/ghostwriting" exact element={<Ghostwriting />} />
        <Route path="/fiction" exact element={<Fiction />} />
        <Route path="/self-help" exact element={<SelfHelp />} />
        <Route path="/children" exact element={<Childrens />} />
        <Route path="/speeches" exact element={<Speeches />} />
        <Route path="/blog-content" exact element={<BlogContent />} />
        <Route path="/business-books" exact element={<BusinessBooks />} />
        <Route
          path="/general-nonfiction"
          exact
          element={<GeneralNonFriction />}
        />
        <Route
          path="/memior-autobiograpghy"
          exact
          element={<MemiorBiography />}
        />
        <Route
          path="/thought-leadership"
          exact
          element={<ThoughtLeadershipPieces />}
        />

<Route
          path="/pricing"
          exact
          element={<PricingPage />}
        />
        
<Route
          path="/privacy-policy"
          exact
          element={<PrivacyPolicy />}
        />
      </Routes> */}
      <Maintenance />



      

      {/* <Footer /> */}
    </Router>
  );
}

export default App;
