import React from "react";
import './Maintenance.css';
import LogoKick from '../../Images/ktnLogo/kick-removebg-preview.png';
import BgImGAE from '../../Images/ktnLogo/bg-image.svg';

const Maintenance = () => {
  return (
    <div 
      className="min-h-screen flex flex-col items-center justify-center bg-[#14143a] bg-cover bg-center px-4 text-center"
      style={{ backgroundImage: `url(${BgImGAE})` }}
    >
      <h1 className="text-3xl mt-10 lg:mt- font-bold text-white mb-4 animate-fadeIn">
        Scheduled Maintenance
      </h1>
      <p className="text-md text-gray-400 mb-2  2xl:mb-10 max-w-3xl">
        Our website is currently undergoing scheduled maintenance we should be back momentarily
      </p>

      <img src={LogoKick} alt="LogoKick" className="w-full max-w-xs 2xl:max-w-xl mb-0 2xl:mb-10  blinking"/>

      <h1 className="text-xl font-bold text-gray-300 mb-2 animate-fadeIn">
        KICK & CO
      </h1>
      <p className="text-md text-gray-400 max-w-3xl">
        Providing communication and marketing solutions for builders of tomorrow
      </p>

      <div className="mt-10">
        <p className="text-md text-gray-300 mb-3 max-w-2xl">Engage:</p>
        <div className="flex space-x-4">
          <a
            href="https://www.linkedin.com/company/kick-co-synergy-ltd/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-white transition-colors duration-200"
          >
            <svg className="w-6 h-6" fill="white" viewBox="0 0 24 24">
              <path d="M22.23 0H1.77C.79 0 0 .77 0 1.72v20.56C0 23.23.79 24 1.77 24h20.46C23.21 24 24 23.23 24 22.28V1.72C24 .77 23.21 0 22.23 0zM7.06 20.452H3.56V9h3.5v11.452zM5.308 7.578c-1.11 0-2.002-.896-2.002-2.001a2.002 2.002 0 012.002-2.002c1.11 0 2.001.896 2.001 2.002 0 1.105-.891 2.001-2.001 2.001zM20.453 20.452h-3.5v-5.688c0-1.356-.028-3.1-1.894-3.1-1.894 0-2.184 1.482-2.184 3.004v5.784h-3.5V9h3.36v1.563h.048c.468-.885 1.61-1.818 3.319-1.818 3.55 0 4.204 2.34 4.204 5.384v6.323z" />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/kick.thenarrative/?g=5"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-white transition-colors duration-200"
          >
            <svg
              className="w-6 h-6"
              fill="white"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Instagram icon</title>
              <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.584-.071 4.849c-.055 1.171-.249 1.805-.415 2.227-.216.562-.476.96-.896 1.382-.421.419-.819.679-1.381.896-.422.166-1.057.361-2.227.413-1.265.057-1.646.071-4.85.071s-3.585-.015-4.849-.071c-1.171-.055-1.805-.249-2.227-.415a3.936 3.936 0 01-1.382-.896c-.419-.421-.679-.819-.896-1.381-.166-.422-.361-1.057-.413-2.227-.057-1.265-.071-1.646-.071-4.849s.016-3.585.071-4.85c.052-1.17.247-1.805.413-2.227.217-.562.477-.96.896-1.382.42-.419.82-.679 1.381-.896.422-.166 1.056-.361 2.227-.413 1.265-.057 1.646-.071 4.85-.071zM12 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zm0 2.16a4.002 4.002 0 110 8.004 4.002 4.002 0 010-8.004zm7.84-1.602a1.44 1.44 0 11-2.88 0 1.44 1.44 0 012.88 0z"></path>
            </svg>
          </a>

          <a
            href="https://www.youtube.com/@KICKTHENARRATIVE"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-white transition-colors duration-200"
          >
            <svg
              className="w-6 h-6"
              fill="white"
              viewBox="0 0 24 24"
            >
              <path d="M23.499 6.203c-.282-1.049-1.093-1.867-2.147-2.129C19.38 3.5 12 3.5 12 3.5s-7.38 0-9.352.574C1.593 4.337.782 5.154.5 6.203.01 8.053 0 12 0 12s.01 3.947.5 5.797c.282 1.049 1.093 1.867 2.147 2.129 1.972.574 9.352.574 9.352.574s7.38 0 9.352-.574c1.054-.262 1.865-1.08 2.147-2.129.49-1.85.5-5.797.5-5.797s-.01-3.947-.5-5.797zM9.75 15.5v-7l6.25 3.5-6.25 3.5z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
